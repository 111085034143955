var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('HeaderDialog',{attrs:{"id":"single_access","title":_vm.singleAccessInfo.title,"show":_vm.show,"loading":_vm.loading,"disabledAction":_vm.defineAccessTime ? _vm.timeError : _vm.defineAccessTime,"width":"1000","closeText":"cancel"},on:{"close":_vm.setDefaultDialogData,"action":function($event){_vm.singleAccessInfo.action === 'grant_single_access_dialog'
      ? _vm.grantSingleAccessDialog()
      : _vm.removeSingleAccessDialog()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"ml-0 mr-0"},[(_vm.singleAccessInfo.action === 'grant_single_access_dialog')?_c('v-col',{staticClass:"px-0"},[_c('p',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(_vm.$ml.get('temporary_access_alert_rules'))}}),_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v(" 1. "+_vm._s(_vm.$ml.get("temporary_access_description"))+" ")]),_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v(" 2. "+_vm._s(_vm.$ml.get("temporary_access_description2"))+" ")]),_c('p',{staticClass:"text-body-2 mb-1"},[_vm._v(" 3. "+_vm._s(_vm.$ml.get("temporary_access_description3"))+" ")]),_c('v-row',{staticClass:"mx-0 mt-1 py-0"},[_c('v-col',{staticClass:"d-flex pa-0 ma-0",attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"label":_vm.$ml.get('time_unit_checkbox_text')},model:{value:(_vm.defineAccessTime),callback:function ($$v) {_vm.defineAccessTime=$$v},expression:"defineAccessTime"}}),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"size":"20"},domProps:{"textContent":_vm._s('mdi-help-circle-outline')}},'v-icon',attrs,false),on))]}}],null,false,2832485748)},[_c('span')])],1),_c('v-col',{staticClass:"d-flex ma-0 pa-0 ml-1",staticStyle:{"gap":"20px"},attrs:{"cols":"12"}},[_c('v-text-field',{staticStyle:{"size":"20px"},attrs:{"type":"number","min":"1","disabled":!_vm.defineAccessTime,"rules":[
                function (value) { return parseInt(value) > 0 || _vm.$ml.get('less_than_rule') + '1'; },

                function (value) { return (parseInt(value) > 0 && parseInt(value) <= 1000) ||
                  _vm.$ml.get('greater_than_rule') + '1000'; } ]},on:{"update:error":function($event){_vm.timeError = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-size":"18px !important"}},[_vm._v(" "+_vm._s(_vm.$ml.get("access_time"))+" ")])]},proxy:true}],null,false,2873190401),model:{value:(_vm.singleAccessTime),callback:function ($$v) {_vm.singleAccessTime=$$v},expression:"singleAccessTime"}}),_c('v-select',{attrs:{"disabled":!_vm.defineAccessTime,"items":['hours', 'minutes']},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticStyle:{"font-size":"18px !important"}},[_vm._v(" "+_vm._s(_vm.$ml.get("time_unit"))+" ")])]},proxy:true},{key:"selection",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ml.get(item))+" ")]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$ml.get(item))+" ")]}}],null,false,4288716502),model:{value:(_vm.singleAccessExpirationTimeUnit),callback:function ($$v) {_vm.singleAccessExpirationTimeUnit=$$v},expression:"singleAccessExpirationTimeUnit"}})],1),_c('v-col')],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-row',[_c('v-card',{staticClass:"mx-auto rounded-lg mb-5",attrs:{"outlined":"","id":"user-logs","max-width":"1400","width":"98%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.userLogs,"items-per-page":5,"hide-default-footer":"","loading":_vm.loadingLogs},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_data"))+" ")]},proxy:true},{key:"top",fn:function(){return [_c('v-card-title',{staticClass:"my-0",staticStyle:{"padding-top":"10px","padding-bottom":"0px"}},[_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.$ml.get("history"))+" ")])])]},proxy:true},{key:"header.date",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateAndHour(item.created_at, true))+" ")]}},{key:"header.event",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.event",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getTranslatedContext(item.context) || item.event)+" ")])]}},{key:"item.target_user",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.target_user)+" ")]}},{key:"header.author_user",fn:function(ref){
              var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey darken-1","dark":"","small":""},domProps:{"textContent":_vm._s('mdi-help-circle')}},'v-icon',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$ml.get("author_description")))])])]}},{key:"header.target_user",fn:function(ref){return [_vm._v(" "+_vm._s(_vm.$ml.get("user"))+" ")]}}],null,true)})],1)],1):_vm._e()],1):_c('v-col',{staticClass:"px-0",attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$ml.get("recomendation_on_remove_temporary_access")))]),(_vm.forceLogout)?_c('Alert',{staticClass:"mt-4",attrs:{"dense":"","columnLayout":"","type":"info","icon":"mdi-information","showAction":false}},[_c('p',{staticClass:"text-body-2 mb-1"},[_c('b',[_vm._v(" "+_vm._s(_vm.$ml.get("attention"))+": ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$ml.get('remove_temporary_access'))}})])]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-checkbox',{attrs:{"label":_vm.$ml.get('force_user_logout')},model:{value:(_vm.forceLogout),callback:function ($$v) {_vm.forceLogout=$$v},expression:"forceLogout"}})],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }